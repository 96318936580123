import React, { useContext, useState } from 'react';
// import ThemeContext from '../../common/ThemeContext.js'
import { NavLink } from "react-router-dom";
import { ReactComponent as ChevronLeft } from '../icons/cheveron-outline-left.svg';
import {IconButton} from 'kalenis-web-components';
import { observer } from 'mobx-react-lite'
import { ThemeContext, SessionContext } from 'kalenis-portal-frontend-main'
import {FormButton} from 'kalenis-web-components'
import {ViewWizardSelector} from 'kalenis-web-components';
const paddings = {
    0: "px-2",
    1: "px-4",
    2: "px-6",
    3: "px-8"
}

const default_background = 'rgb(250,250,255)'
const default_text = 'black'



function CategoryMenuItem(props) {
    const { item, level } = props
    const baseClassName = "flex cursor-pointer font-semibold  py-2 border-b-0.5 border-gray-300 text-xs ".concat(paddings[level])
    const className = props.className ? props.className.concat(baseClassName) : baseClassName
    const bg_color = item.bg_color ? item.bg_color : default_background
    const color = item.text_color ? item.text_color : default_text

    return (
        <div onClick={() => { props.onClick(item.id) }} style={{ ...props.style, backgroundColor: bg_color, color: color }} className={className}>
            {props.icon ? props.icon : ""}
            {item.name}
        </div>
    )
}

function ListItem(props) {
    const { item, level } = props
    const baseClassName = "flex py-2 border-b-0.5 border-gray-300 text-xs ".concat(paddings[level])
    const className = props.className ? props.className.concat(baseClassName) : baseClassName
    const session = useContext(SessionContext)
    const route = session.getRouteById(item)


    return (
        <>
            {route &&
                <NavLink key={item} to={route.path}
                    activeClassName="text-primary font-medium bg-light-hover"
                >
                    <div onClick={props.onClick} style={{ backgroundColor: 'inherit' }} className={className}>
                        {props.icon ? props.icon : ""}
                        {route.sidebar.title}
                    </div>

                </NavLink>
            }

        </>

    )
}

const SideBarMenu = observer(function SidebarMenu(props) {
    const [expanded, setExpanded] = useState([])
    const theme = useContext(ThemeContext);
    const session = useContext(SessionContext)
    const tree = session.menu_tree
    const view_editor = (session && session.user_context) ? session.user_context.view_editor:false
    const [wizard_dialog, setWizardDialog] = useState(false)

    function updateExpanded(id) {
        const idx = expanded.indexOf(id)
        const exp = [...expanded]
        if (idx >= 0) {
            exp.splice(idx, 1)
        }
        else {
            exp.push(id)
        }
        setExpanded(exp)

    }
    const renderMenu = (item, level = 0) => {
        if (item.type === 'category') {
            return (
                <>
                    <CategoryMenuItem item={item} level={level} onClick={updateExpanded} />
                    {(item.childs && expanded.includes(item.id)) &&
                        <>
                            {Object.keys(item.childs).map((key, index) => (
                                <React.Fragment key={index}>
                                    {renderMenu(item.childs[key], level + 1)}
                                </React.Fragment>



                            ))}
                        </>
                    }
                </>
            )
        }
        else {
            return (<ListItem item={item} level={level} onClick={props.setMobileOpen} />)
        }
    }


    return (

        <>

            <div className="p-4 border-b-0.5 border-gray-300">
                <img alt="logo" src={theme.logo}></img>
            </div>


            <div style={{height: props.calcHeight}} className="mb-12 overflow-scroll">
                {tree.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderMenu(item)}
                    </React.Fragment>



                ))}




            </div>

            
            <div className="absolute bottom-0 bg-background w-full" >
                <div className="flex flex-col">
                {view_editor && 
                <>
                    {wizard_dialog &&
                        <ViewWizardSelector open={wizard_dialog} close={()=>{setWizardDialog(false)}}/>
                    }
                    
                    <FormButton state_attrs={{}} onClick={()=>{setWizardDialog(true)}} color="transparent">+ Nuevo </FormButton>
                </>
                }
                
                <IconButton onClick={(e) => props.setMenuWidth(0)} color="transparent">
                    <ChevronLeft style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>
                </div>
            </div>

        </>
    )

})


export default SideBarMenu;